import React from 'react';
import styled from 'styled-components';
import {
  transitionDuration,
} from './Utils';

const activeColor = '#fab480';

const Root = styled.li`
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: block;
  transition: color ease-in-out ${transitionDuration}ms;

  &:hover {
    color: ${activeColor};
  }
`;

interface IProps {
  selectedCountry: number | undefined;
  id: number;
  name: string;
  onClick: (id: number) => void;
}

export default class extends React.Component<IProps> {
  private onClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    const {onClick, id} = this.props;
    onClick(id);
  }

  render() {
    const {name, id, selectedCountry} = this.props;
    const style = {
      color: (id === selectedCountry) ? activeColor : '',
    };

    return (
      <Root onClick={this.onClick} style={style}>{name}</Root>
    );
  }
}
