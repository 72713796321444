import { GetString } from 'fluent-react';
import React, { useContext } from 'react';
import { HomePageLocalizationAndBundleContext } from '../contextProviders/getFluentLocalizationContexts';
import Overlay from '../overlay';
import Footer from '../sharedComponents/Footer';
import {
  Grid as GlobalGrid,
} from '../sharedComponents/GlobalGrid';
import Content from './Content';
import Header from './Header';
import HeaderBackground from './HeaderBackground';

interface Props {
  banner: React.ReactNode;
  overlayBackgroundZIndex: number;
  overlayContentZIndex: number;
}

const Landing = (props: Props) => {
  const {banner, overlayBackgroundZIndex, overlayContentZIndex} = props;

  const {localization} = useContext(HomePageLocalizationAndBundleContext);
  const getFluentString: GetString =
    (id: string, args?: object) => localization.getString(id, args);

  return (
    <GlobalGrid>
      {banner}
      <HeaderBackground/>
      <Header getFluentString={getFluentString} />
      <Content getFluentString={getFluentString} />
      <Footer/>
      <Overlay
        backgroundZIndex={overlayBackgroundZIndex}
        contentZIndex={overlayContentZIndex}
      />
    </GlobalGrid>
  );
};

export default Landing;
