import React from 'react';
import Country from './Country';
import {
  IDatum,
} from './Utils';
const styles = require('./map.css');

interface IProps {
  paths: IDatum[];
  onSelect: (id: number) => void;
}

const regularClassName = styles.otherCountries;
export default class extends React.PureComponent<IProps, {}> {
  render() {
    const {paths, onSelect} = this.props;
    const countries = paths.map(({id, path}: IDatum, index: number) => {
      // id -1 is a special marker for countries that don't exist in our metadata
      const key = (id === -1) ? `${id}-${index}` : id;
      return (
        <Country
          id={id} path={path}
          regularClassName={regularClassName} onSelect={onSelect}
          key={key} hasProjectInCountry={false}
        />
      );
    });
    return (
      <g>{countries}</g>
    );
  }
}
