import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {
  HeaderBackgroundContainer,
} from '../sharedComponents/GlobalGrid';
import { headerHeight } from './Header';
import { breakpointSmall } from './Utils';

const backgroundGradient = 'linear-gradient(90deg, #222833 0%, #425069 100%)';

const VideoContainer = styled(HeaderBackgroundContainer)`
  overflow: hidden;
  background: ${backgroundGradient};

  @media (min-width: ${breakpointSmall}px) {
    height: ${headerHeight};
  }
`;

const VideoBackground = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.25;
`;

const HeaderBackground = () => {

  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.muted = true;
    }
  }, [videoRef]);

  return (
    <VideoContainer>
      <VideoBackground
        ref={videoRef}
        loop={true}
        autoPlay={true}
      >
        <source src={require('./videos/header-video.mp4')} />
      </VideoBackground>
    </VideoContainer>
  );
};

export default HeaderBackground;
