import { GetString } from 'fluent-react';
import React from 'react';
import styled, {
  css,
} from 'styled-components';
import { footerColor } from '../sharedComponents/Footer';
import {
  LandingContentContainer,
} from '../sharedComponents/GlobalGrid';
import {
  H2,
  H4,
  SubSectionHeader,
} from '../sharedComponents/TextStyling';
import { googleAnalyticsEvent } from '../useGoogleAnalytics';
import IntroCards from './IntroCards';
import Map from './map';
import TwitterFeed from './TwitterFeed';
import {
  breakpointSmall,
  PageSection,
} from './Utils';

const containerMobileSpacing = '20px';
const containerDesktopSpacing = '100px';

const Container = styled(LandingContentContainer)`
  line-height: 1.5;
  margin: 0 auto;
  margin-top: -30px;
  display: grid;
  grid-template-columns: ${containerMobileSpacing} auto ${containerMobileSpacing};

  @media (min-width: 1000px) {
    grid-template-columns: ${containerDesktopSpacing} auto ${containerDesktopSpacing};
  }
`;

const InvertedSubSectionHeader = styled(SubSectionHeader)`
  color: white;
`;

const TestimonyAndNewsSection = styled(PageSection)`
  grid-column: span 3;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  grid-template-rows: 3rem repeat(auto, 4);
  color: white;
  background-color: ${footerColor};
  padding: 20px ${containerMobileSpacing};

  @media (min-width: 1000px) {
    padding: 20px ${containerDesktopSpacing};
  }

  @media (min-width: ${breakpointSmall}px) {
    grid-template-columns: 1fr 2fr 2fr;
    grid-column-gap: 3rem;
  }
`;

const HighlightedSection = styled(PageSection)`
  grid-column: span 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  padding: 0 ${containerMobileSpacing};

  @media (min-width: 1000px) {
    padding: 3rem ${containerDesktopSpacing};
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const VizHubSection = styled(HighlightedSection)`
  background-image: url("${require('./img/VizHubPattern.png')}");
  background-size: cover;
  background-position: center;
  padding: 3rem ${containerMobileSpacing};
`;

const NewsLogoContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1;
  margin: 0 auto;
  margin-top: 2.5rem;
  max-width: 180px;

  @media (min-width: ${breakpointSmall}px) {
    margin-top: 0;
  }
`;

const NewsLogoContainer1 = styled(NewsLogoContainer)`
  @media (min-width: ${breakpointSmall}px) {
    max-width: 80%;
  }
`;
const NewsLogoContainer4 = styled(NewsLogoContainer)`
  @media (min-width: ${breakpointSmall}px) {
    max-width: 40%;
  }
`;
const NewsTitleContainer = styled.a`
  display: block;
  font-size: 1.25rem;
  grid-column: 1;
  text-align: center;

  @media (min-width: ${breakpointSmall}px) {
    grid-column: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }
`;

const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1;

  @media (min-width: ${breakpointSmall}px) {
    grid-column: 3;
    grid-row: 2 / 6;
  }
`;

const TestimonyQuoteContainer = styled.div`
  justify-content: flex-start;
`;
const TestimonyQuote = styled(H2)`
  font-size: 1.2rem;
  color: white;
`;

const newsSectionStyle = css`
  letter-spacing: 0.1rem;
  margin-bottom: 8vh;
  grid-column: 1;
`;

const NewNewsTitle = styled.div`
  ${newsSectionStyle}
  grid-row: 1;
  grid-column: 1 / 4;
  text-align: center;
  border-bottom: 1px solid #fff;
  display: block;
  margin: 0 auto;
  width: 100%;
`;

const ResourcesContainer = styled(PageSection)`
  display: grid;
  grid-row-gap: 5%;
  margin-bottom: 10vh;

  @media (min-width: ${breakpointSmall}px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5%;
    margin-bottom: 5vh;
  }
`;

const buttonColor = '#666';
const CallToAction = styled.div`
  display: block;
  border: 1px solid ${buttonColor};
  padding: 0.5rem;
  background-color: #fff;
  color: ${buttonColor};
  float: right;
  border-radius: 30px;
  text-transform: uppercase;
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  &:hover {
    color: #fff;
    background-color: ${buttonColor};
  }
`;
const EBookContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-size: 100% 100%;

  @media (min-width: ${breakpointSmall}px) {
    grid-row: 1;
    grid-column: 2;
  }
`;

const BookImageContainer = styled.div`
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 15%;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.28);
`;

const HarvardGazetteLogoContainer = styled.div`
  svg {
    width: 100%;
    max-height: 65px;

    path {
      fill: #fff;
    }
  }
`;

const VizHubTitle = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
`;
const VizHubLogo = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  svg {
    width: 100%;
    height: 100%;

    .cls-1 {
      fill: #dc5052;
      opacity: 0.9;
    }

    .cls-2 {
      fill: #47566d;
    }
  }
`;
const MetroverseLogo = styled.img`
  width: 100%;
  margin-bottom: 3rem;
`;
const VizHubButton = styled.a`
  background-color: #fff;
  padding: 0.5rem 1.2rem 0.5rem 2rem;
  font-size: 1.25rem;
  border-radius: 200px;
  border: solid 1px #47566d;
  color: #47566d;
  text-transform: uppercase;

  svg {
    width: 0.7rem;
    height: 0.7rem;
    fill: #dc5052;
    margin-left: 0.7rem;
  }

  &:hover {
    background-color: #47566d;
    color: #fff;

    svg {
      fill: #fff;
    }
  }
`;
const VizHubDescription = styled.div`
  font-size: 1.25rem;
  line-height: 1.7;
  background-color: #fff;
  padding: 1.5rem 2rem;
  border-left: solid 4px #bbb04a;
`;

const MetroverseDescription = styled(VizHubDescription)`
  background-color: #dcdfe5;
`;

interface Props {
  getFluentString: GetString;
}

const Content = (props: Props) => {
  const { getFluentString } = props;
  const bloombergLink =
/* tslint:disable-next-line:max-line-length */
`https://www.bloomberg.com/opinion/articles/2019-09-18/repo-market-spike-carries-echoes-of-2007-2008-crisis-k0oqoebp`;
  const harvardGazetteLink =
/* tslint:disable-next-line:max-line-length */
`https://news.harvard.edu/gazette/story/2019/09/kennedy-schools-growth-lab-tool-helps-chart-paths-for-economic-growth/`;
  const ftLink = `https://www.ft.com/content/0297ff7c-524e-11e8-b3ee-41e0209208ec`;
  const nprLink = `https://www.npr.org/sections/money/2018/02/28/589609380/episode-827-three-indicators`;

  return (
    <Container>
      <IntroCards getFluentString={getFluentString} />

      <VizHubSection>
        <VizHubTitle>
          <VizHubLogo
            dangerouslySetInnerHTML={{__html: require('./img/VizHubIcon.svg')}}
          />
          <div>
            <VizHubButton href='https://growthlab.app/' target='_blank' rel='noopener'>
             Go to Viz Hub
               <span
                dangerouslySetInnerHTML={{__html: require('./img/arrow.svg')}}
              />
            </VizHubButton>
          </div>
        </VizHubTitle>
        <VizHubDescription>
          {getFluentString('homepage-viz-hub-about')}
        </VizHubDescription>
      </VizHubSection>
      <HighlightedSection>
        <VizHubTitle>
          <MetroverseLogo
            src={require('./img/metroverse-logo.png')}
          />
          <div>
            <VizHubButton
            href='https://metroverse.cid.harvard.edu/'
            target='_blank'
            rel='noopener'
            onClick={() => googleAnalyticsEvent(
              'Landing Page Callouts Explore Metroverse',
              'click -- explore further in Metroverse',
            )}
          >
             Go to Metroverse
               <span
                dangerouslySetInnerHTML={{__html: require('./img/arrow.svg')}}
              />
            </VizHubButton>
          </div>
        </VizHubTitle>
        <MetroverseDescription>
          {getFluentString('homepage-metroverse-about')}
        </MetroverseDescription>
      </HighlightedSection>
      <TestimonyAndNewsSection>
        <NewNewsTitle>
          <InvertedSubSectionHeader>
            {getFluentString('homepage-news-title')}
          </InvertedSubSectionHeader>
        </NewNewsTitle>
        <NewsLogoContainer1 href={bloombergLink} target='_blank' rel='noopener'>
          <img src={require('./img/Bloomberg_white.png')}/>
        </NewsLogoContainer1>
        <NewsTitleContainer href={bloombergLink} target='_blank' rel='noopener'>
          {getFluentString('homepage-news-article1-title')}
        </NewsTitleContainer>

        <NewsLogoContainer1 href={harvardGazetteLink} target='_blank' rel='noopener'>
          <HarvardGazetteLogoContainer
            dangerouslySetInnerHTML={{__html: require('./img/harvard-gazette.svg')}}
          />
        </NewsLogoContainer1>
        <NewsTitleContainer href={harvardGazetteLink} target='_blank' rel='noopener'>
          {getFluentString('homepage-news-article2-title')}
        </NewsTitleContainer>

        <NewsLogoContainer1 href={ftLink} target='_blank' rel='noopener'>
          <img src={require('./img/financialtimes_white.png')}/>
        </NewsLogoContainer1>
        <NewsTitleContainer href={ftLink} target='_blank' rel='noopener'>
          {getFluentString('homepage-news-article3-title')}
        </NewsTitleContainer>

        <NewsLogoContainer4 href={nprLink} target='_blank' rel='noopener'>
          <img src={require('./img/npr_white.png')}/>
        </NewsLogoContainer4>
        <NewsTitleContainer href={nprLink} target='_blank' rel='noopener'>
          {getFluentString('homepage-news-article4-title')}
        </NewsTitleContainer>

        <TestimonialContainer>
          <TestimonyQuoteContainer>
            <TestimonyQuote> {getFluentString('homepage-news-testimony-text1')} </TestimonyQuote>
            <H4> {getFluentString('homepage-news-testimony-source1')} </H4>
          </TestimonyQuoteContainer>

          <TestimonyQuoteContainer>
            <TestimonyQuote> {getFluentString('homepage-news-testimony-text2')} </TestimonyQuote>
            <H4> {getFluentString('homepage-news-testimony-source2')} </H4>
          </TestimonyQuoteContainer>

          <TestimonyQuoteContainer>
            <TestimonyQuote> {getFluentString('homepage-news-testimony-text3')} </TestimonyQuote>
            <H4> {getFluentString('homepage-news-testimony-source3')} </H4>
          </TestimonyQuoteContainer>
        </TestimonialContainer>
      </TestimonyAndNewsSection>

      <Map getFluentString={getFluentString} />

      <ResourcesContainer>
        <TwitterFeed/>
        <EBookContainer style={{backgroundImage: `url('${require('../img/book-pattern_image.png')}')`}}>
          <BookImageContainer
            dangerouslySetInnerHTML={{__html: require('../img/atlas_bookjacket.svg')}}
          />
          <CallToAction>
            <a href='https://growthlab.cid.harvard.edu/publications/format/books' target='_blank' rel='noopener'>
              {getFluentString('homepage-book-call-to-action')}
            </a>
          </CallToAction>
        </EBookContainer>
      </ResourcesContainer>

    </Container>
  );
};

export default Content;
