import { GetString } from 'fluent-react';
import React from 'react';
import styled, {
  css,
} from 'styled-components';
import {
  semiBoldFontWeight,
} from '../../cssVariables';
import { footerColor } from '../../sharedComponents/Footer';
import {
  H2,
  H6,
} from '../../sharedComponents/TextStyling';
import {
  getHeightFromWidth,
  IProjectDatum,
  mapTopMargin,
} from './Utils';

export const Overlay = styled.div`
  top: 0;
  overflow-y: auto;
  position: absolute;
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
  line-height: 1.5;
  color: #333;
  padding: 5px;
  padding-bottom: 1vh;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.3);
`;

const Root = styled(Overlay)`
  border-top: 4px solid #fab480;
  left: 0;
`;

const textStyle = css`
  opacity: 1;
`;

const Description = styled(H6)`
  ${textStyle}
`;

const CountryName = styled.div`
  ${textStyle}
  font-size: 1rem;
  color: rgba(100, 100, 180, 0.5);
  margin: 0.5rem 0;
`;

const ProjectLink = styled.div`
  ${textStyle}
  color: ${footerColor};
  font-weight: ${semiBoldFontWeight};
  font-size: 0.85rem;
`;

// The "project link" part of the detail overlay can either show an actual link
// once a country is selected or a call to action otherwise.
type LinkProps = {
  showProjectLink: true;
  projectDatum: IProjectDatum,
} | {
  showProjectLink: false;
  placeholderText: string;
  title: string;
  description: string;
};

type IProps = LinkProps & {
  chartWidth: number;
  getFluentString: GetString;
};
export default class extends React.PureComponent<IProps, {}> {
  // Without `stopPropagation`, clicking on the detail overlay outside of any
  // country will close the detail overlay:
  private onClick = (e: React.MouseEvent<any>) => e.stopPropagation();

  render() {
    const props = this.props;

    const {
      chartWidth, getFluentString,
    } = props;
    // Percentage of overlay's width relative to chart's width:
    const overlayWidthPercentage = 0.29;
    // Percentage of overlay's horizontal padding relative to overlay's width:
    const horizontalPaddingPercentage = 0.1;
    // Percentage of overlay's vertical padding relative to overlay's height:
    const verticalPaddingPercentage = 0.11;
    // Percentage of overlay's vertical margin relative to chart's height:

    const overlayWidth = chartWidth * overlayWidthPercentage;
    const horizontalPadding = `${overlayWidth * horizontalPaddingPercentage}`;
    const chartHeight = getHeightFromWidth(chartWidth);
    const verticalPadding = chartHeight * verticalPaddingPercentage;

    const style: React.CSSProperties = {
      width: overlayWidth,
      padding: `${verticalPadding}px ${horizontalPadding}px`,
      maxHeight: `calc(100% + ${mapTopMargin})`,
    };

    let projectLink: JSX.Element | null, name: string, text: string;
    if (props.showProjectLink === true) {
      const {projectDatum} = props;
      ({name, text} = projectDatum);
      text = getFluentString(text);
      if (projectDatum.hasLink === true) {
        const {link} = projectDatum;
        projectLink = (
          <a href={link} target='_blank' rel='noopener'>{link}</a>
        );

      } else {
        projectLink = null;
      }
    } else {
      name = props.title;
      text = props.description;
      projectLink = (
        <span>{props.placeholderText}</span>
      );
    }

    return (
      <Root style={style} onClick={this.onClick}>
        <CountryName><H2>{name}</H2></CountryName>
        <Description>
          {text}
        </Description>
        <ProjectLink>
          <p>{projectLink}</p>
        </ProjectLink>
      </Root>
    );
  }
}
