import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import {
  hoverTooltipDelayDuration as hoverDelayDuration,
  transitionDuration,
} from './Utils';

const Path = styled.path`
  transition:
    fill ${transitionDuration}ms,
    stroke ${transitionDuration}ms,
    stroke-width ${transitionDuration}ms;
`;

type ISelectProps = {
  hasProjectInCountry: false;
} | {
  hasProjectInCountry: true,
  isSelected: boolean;
  onMouseEnter: (id: number) => void;
  onMouseLeave: () => void;
  selectedClassName: string;
};

type IProps = ISelectProps & {
  id: number;
  path: string;
  regularClassName: string;
  onSelect: (id: number) => void;
};

export default class extends React.PureComponent<IProps, {}> {
  private hoverDelayTimer: number | undefined;
  private onMouseEnter = () => {
    const props = this.props;
    if (props.hasProjectInCountry === true) {
      this.hoverDelayTimer = setTimeout(() => {
        const {id, onMouseEnter} = props;
        onMouseEnter(id);
        this.hoverDelayTimer = undefined;
      }, hoverDelayDuration);

    }
  }
  private onMouseLeave = () => {
    const props = this.props;
    if (props.hasProjectInCountry === true) {
      if (this.hoverDelayTimer === undefined) {
        const {onMouseLeave} = props;
        onMouseLeave();
      } else {
        clearTimeout(this.hoverDelayTimer);
      }

    }
  }
  private onClick = (e: React.MouseEvent<any>) => {
    // Need to stop this event from propagating up because once a click event
    // reaches the map container, it will clear the country selection because
    // the click is assumed to be outside all countries:
    e.stopPropagation();

    const props = this.props;
    props.onSelect(props.id);
  }
  render() {
    const props = this.props;
    const {path, regularClassName} = props;

    let className: string;
    if (props.hasProjectInCountry === true) {
      className = classnames(regularClassName, {
        [props.selectedClassName]: props.isSelected,
      });
    } else {
      className = regularClassName;
    }

    return (
      <Path d={path} className={className}
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}/>
    );
  }
}
