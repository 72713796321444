import TweenLite from 'gsap/TweenLite';
import React from 'react';
import Transition, {
  TransitionProps,
} from 'react-transition-group/Transition';

const onEnter =
    (node: HTMLElement) =>
      TweenLite.set(node, {opacity: 0});

const onEntering =
  (timeout: number, targetOpacity: number) =>
    (node: HTMLElement) =>
      TweenLite.to(node, timeout / 1000, {opacity: targetOpacity});

const onExiting =
  (timeout: number) =>
    (node: HTMLElement) =>
      TweenLite.to(node, timeout / 1000, {opacity: 0});

interface IProps extends TransitionProps {
  timeout: number;
  immediateRenderAtStart?: boolean;
  normalOpacity?: number;
}

export default class extends React.PureComponent<IProps> {
  render() {
    const {
      timeout,
      children,
      normalOpacity = 1,
      ...rest} = this.props;
    return (
      <Transition {...rest}
        timeout={timeout}
        appear={true}
        onEnter={onEnter}
        onEntering={onEntering(timeout, normalOpacity)}
        onExiting={onExiting(timeout)}>

        {children}

      </Transition>
    );
  }
}
