import {
  geoPath,
  geoStereographic,
} from 'd3-geo';
import {
  PathGenerator,
} from './Utils';

const getPathGenerator = (width: number, height: number): PathGenerator => {
  const projection = geoStereographic()
                    // `scale` is chosen so that the top and bottom of the map
                    // coincides with the north and south poles:
                    .scale(260 * width / 1200)
                    .translate([width / 2, height / 2])
                    .clipAngle(150 - 1e-4)
                    .clipExtent([[0, 0], [width, height]])
                    .precision(1);
  const path = geoPath().projection(projection);
  return path;

};

export default getPathGenerator;
