import {
  GeoPath,
  GeoPermissibleObjects,
} from 'd3-geo';
import {
  GenericGeoJSONFeature,
} from '../../../types/generic-geojson';

export interface IDatum {
  id: number;
  path: string;
  centerX: number;
  bottomY: number;
}

export interface IComputationalOutput {
  pathMap: Map<number, IDatum>;
  pathsForNoProjectCountries: IDatum[];
  pathsForProjectCountries: IDatum[];
  graticulePath: string;
}
export type GeoJSONFeature = GenericGeoJSONFeature<GeoJSON.MultiPolygon, {}>;
export type PathGenerator = GeoPath<any, GeoPermissibleObjects>;

export type IProjectDatumWithoutName = {
  id: number;
  text: string;
} & ({hasLink: false} | {hasLink: true, link: string});

export type IProjectDatum = IProjectDatumWithoutName & {
  name: string;
};

export const hoverTooltipDelayDuration = 350; // in ms

export const transitionDuration = 350; // in milliseconds
export const tooltipTransitionDuration = 100; // in milliseconds

export const mapTopMargin = '1.1vh';

export const aspectRatio = 2.3;

export const getHeightFromWidth = (height: number) => height / aspectRatio;
