import React from 'react';
import styled from 'styled-components';

// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--translate-x'?: string;
    '--translate-y'?: string;
  }
}
const Root = styled.div`
  --translate-x: 0;
  --translate-y: 0;

  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(calc(-50% + var(--translate-x))) translateY(calc(50% + var(--translate-y)));
  font-size: 0.7rem;
`;

interface IProps {
  text: string;
  x: number;
  y: number;
}

export default class extends React.PureComponent<IProps, {}> {
  render() {
    const {text, x, y} = this.props;
    const style = {
      '--translate-x': `${x}px`,
      '--translate-y': `${y}px`,
    };
    return (
      <Root style={style}>
        {text}
      </Root>
    );
  }
}
