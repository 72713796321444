import {
  IMetadatum,
} from '../../sharedData/newCountryMetadata';
import {
  ILoadable,
  LoadableStatus,
} from '../../Utils';
import {
  IProjectDatum,
  IProjectDatumWithoutName,
} from './Utils';

const merge = (
    metadataStatus: ILoadable<Map<number, IMetadatum>>,
    projectInfo: IProjectDatumWithoutName[],
  ): ILoadable<Map<number, IProjectDatum>> => {

  let result: ILoadable<Map<number, IProjectDatum>>;
  if (metadataStatus.status === LoadableStatus.Initial) {
    result = {status: LoadableStatus.Initial};
  } else if (metadataStatus.status === LoadableStatus.Loading) {
    result = {status: LoadableStatus.Initial};
  } else if (metadataStatus.status === LoadableStatus.NotPresent) {
    result = {status: LoadableStatus.Initial};
  } else {
    const {data: metadata} = metadataStatus;
    const merged: IProjectDatum[] = projectInfo.map(datum => ({
      ...datum,
      name: metadata.get(datum.id)!.name_short_en,
    }));
    const pairs: Array<[number, IProjectDatum]> = merged.map(
      datum => ([datum.id, datum] as [number, IProjectDatum]),
    );
    const map = new Map(pairs);
    result = {
      status: LoadableStatus.Present,
      data: map,
    };
  }
  return result;
};

export default merge;
