import React from 'react';
import {
  connect,
  DispatchProp,
  MapStateToProps,
} from 'react-redux';
import {
  IRootState,
} from '../../rootState';
import {
  fetchIfNeeded as fetchGeoJSONIfNeeded,
  getDataSelector as getGeoJSONSelector,
} from '../../sharedData/newWorldGeoJSON';
import {
  ILoadable,
  LoadableStatus,
} from '../../Utils';
import {
  PageSection,
} from '../Utils';
const styles = require('./map.css');
import { GetString } from 'fluent-react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import styled from 'styled-components';
import memoize from '../../memoize';
import Loading from '../../sharedComponents/GraphLoading';
import {
  fetchIfNeeded as fetchCountryMetadataIfNeeded,
  getDataSelector as getCountryMetadataSelector,
  IMetadatum,
} from '../../sharedData/newCountryMetadata';
import {
  IData as IGeoJSON,
} from '../../sharedData/newWorldGeoJSON';
import CountriesWithProject from './CountriesWithProject';
import {
  countriesIDsWithCurrentProjects,
  countriesWithProjects,
} from './countriesWithProjectInfo';
import CountryList from './CountryList';
import Overlay from './DetailOverlay';
import Fade from './Fade';
import unmemoizedGetPathGenerator from './getPathGenerator';
import unmemoizedGetSVGPaths from './getSVGPaths';
import unmemoizedMergeMetadata from './mergeMetadataWithProject';
import OtherCountries from './OtherCountries';
import Tooltip from './Tooltip';
import {
  getHeightFromWidth,
  hoverTooltipDelayDuration,
  mapTopMargin,
  transitionDuration,
} from './Utils';

const Root = styled(PageSection)`
  justify-content: center;
  width: 100%;
  position: relative;
  margin: 10.8vh 0 5vh;
`;
const Svg = styled.svg`
  margin-top: ${mapTopMargin};
`;
const ChartContainer = styled.div`
  position: relative;
`;

export type IOwnProps = {};
interface IStateProps {
  geoJSONStatus: ILoadable<IGeoJSON>;
  metadataStatus: ILoadable<Map<number, IMetadatum>>;
}

type IProps = IOwnProps & IStateProps & DispatchProp & {
  getFluentString: GetString;
};

interface IState {
  selectedCountry: number | undefined;
  hoveredCountry: number | undefined;
  width: number | undefined;
  height: number | undefined;
}

const spinnerKey = 'spinner';

class LandingMap extends React.PureComponent<IProps, IState> {
  private el: HTMLElement | null = null;
  private rememberEl = (el: HTMLElement | null) => {
    this.el = el;
    this.measureDOMLayout();
  }

  private getPathGenerator = memoize(unmemoizedGetPathGenerator);
  private getSVGPaths = memoize(unmemoizedGetSVGPaths);
  private getProjectData = memoize(unmemoizedMergeMetadata);
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedCountry: undefined,
      hoveredCountry: undefined,
      width: undefined,
      height: undefined,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchGeoJSONIfNeeded({}));
    this.props.dispatch(fetchCountryMetadataIfNeeded({}));
  }

  /* Start of size measurement */
  private measureDOMLayout() {
    if (this.el !== null) {
      const {
        width,
      } = this.el.getBoundingClientRect();

      const height = getHeightFromWidth(width);
      this.setState(prevState => ({
        ...prevState,
        width, height,
      }));
    }
  }

  private onSelect = (selectedCountry: number) => this.setState(
    prevState => ({...prevState, selectedCountry, noCountrySelectedYet: false}),
  )

  private resetSelection = () => this.setState(
    (prevState: IState): IState => ({...prevState, selectedCountry: undefined}),
  )

  private onMouseEnter = (hoveredCountry: number) => this.setState(
    prevState => ({...prevState, hoveredCountry}),
  )

  private onMouseLeave = () => this.setState(
    prevState => ({...prevState, hoveredCountry: undefined}),
  )

  render() {
    const {
      selectedCountry, hoveredCountry, width, height,
    } = this.state;

    let chart: JSX.Element | null;
    let loadingSpinner: JSX.Element | null;
    if (width === undefined || height === undefined) {
      chart = null;
      loadingSpinner = (
        <Fade timeout={transitionDuration} key={spinnerKey}>
          <Loading/>
        </Fade>
      );
    } else {
      const {geoJSONStatus, metadataStatus, getFluentString} = this.props;
      const generatePath = this.getPathGenerator(width, height);
      const computedData = this.getSVGPaths(generatePath, countriesIDsWithCurrentProjects, geoJSONStatus);
      const projectData = this.getProjectData(metadataStatus, countriesWithProjects);

      const defaultDetailOverlay = (
        <Fade timeout={transitionDuration}>
          <Overlay
            chartWidth={width}
            title={getFluentString('atlas-in-action-map-viz-initial-instructions-title')}
            description={getFluentString('atlas-in-action-map-viz-initial-instructions-subtitle')}
            showProjectLink={false}
            placeholderText={getFluentString('atlas-in-action-map-viz-initial-instructions-call-to-action')}
            getFluentString={getFluentString}/>
        </Fade>
      );

      let otherCountries: JSX.Element | null;
      let countriesWithProject: JSX.Element | null;
      let graticule: JSX.Element | null;
      if (computedData.status === LoadableStatus.Present) {
        const {data: {
          pathsForNoProjectCountries, pathsForProjectCountries, graticulePath,
        }} = computedData;
        otherCountries = (
          <OtherCountries paths={pathsForNoProjectCountries} onSelect={this.resetSelection}/>
        );
        countriesWithProject = (
          <CountriesWithProject
            paths={pathsForProjectCountries}
            selectedCountry={selectedCountry}
            onSelect={this.onSelect}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}/>
        );
        graticule = (
          <path d={graticulePath} className={styles.graticule}/>
        );
        loadingSpinner = null;
      } else {
        otherCountries = null;
        countriesWithProject = null;
        graticule = null;
        loadingSpinner = (
          <Fade timeout={transitionDuration} key={spinnerKey}>
            <Loading/>
          </Fade>
        );
      }

      /* Start of hover tooltip */
      let hoverTooltip: JSX.Element | null;
      if (hoveredCountry !== undefined &&
            projectData.status === LoadableStatus.Present &&
            computedData.status === LoadableStatus.Present) {
        const {name} = projectData.data.get(hoveredCountry)!;
        const {centerX, bottomY} = computedData.data.pathMap.get(hoveredCountry)!;
        hoverTooltip = (
          <Fade timeout={hoverTooltipDelayDuration}>
            <Tooltip text={name} x={centerX} y={bottomY}/>
          </Fade>
        );
      } else {
        hoverTooltip = null;
      }
      /* End of hover tooltip */

      /* Start of detail overlay */
      // Initially, show the overlay with the call to action even if no country
      // is selected. After that, if no country is selected, don't show the
      // overlay:
      let detailOverlay: JSX.Element | null;
      if (selectedCountry === undefined) {
        detailOverlay = defaultDetailOverlay;
      } else {
        if (projectData.status === LoadableStatus.Present) {
          const projectDatum = projectData.data.get(selectedCountry)!;
          detailOverlay = (
            <Fade timeout={transitionDuration}>
              <Overlay chartWidth={width} projectDatum={projectDatum}
                showProjectLink={true}
                getFluentString={getFluentString}/>
            </Fade>
          );
        } else {
          detailOverlay = defaultDetailOverlay;
        }
      }
      /* End of detail overlay */

      //#region Country list
      let countryList: JSX.Element | null;
      if (projectData.status === LoadableStatus.Present) {
        countryList = (
          <Fade timeout={transitionDuration}>
            <CountryList
              data={projectData.data}
              selectedCountry={selectedCountry}
              onClick={this.onSelect}/>
          </Fade>
        );
      } else {
        countryList = null;
      }
      //#endregion

      const svg = (
        <Fade timeout={transitionDuration}>
          <Svg width={width} height={height}>
            {otherCountries}
            {countriesWithProject}
            {graticule}
          </Svg>
        </Fade>
      );
      const style = {
        width: `${width}px`,
        height: `${height}px`,
      };
      chart = (
        <Fade timeout={transitionDuration}>
          <TransitionGroup style={style} component={ChartContainer as any} onClick={this.resetSelection}>
            {svg}
            {detailOverlay}
            {countryList}
            {hoverTooltip}
          </TransitionGroup>
        </Fade>
      );
    }

    return (
      <Root ref={this.rememberEl}>
        <TransitionGroup>
          {chart}
          {loadingSpinner}
        </TransitionGroup>
      </Root>
    );
  }
}

const mapStateToProps: () => MapStateToProps<IStateProps, IOwnProps, IRootState> = () => {
  const getGeoJSON = getGeoJSONSelector();
  const getCountryMetadata = getCountryMetadataSelector();
  return (state: IRootState) => {
    const geoJSONStatus = getGeoJSON(state, {});
    const metadataStatus = getCountryMetadata(state, {});
    return {
      geoJSONStatus,
      metadataStatus,
    };
  };
};

export default connect(mapStateToProps)(LandingMap);
