import React from 'react';
import Country from './Country';
import {
  IDatum,
} from './Utils';
const styles = require('./map.css');

interface IProps {
  paths: IDatum[];
  selectedCountry: number | undefined;
  onSelect: (id: number) => void;
  onMouseEnter: (id: number) => void;
  onMouseLeave: () => void;
}

const regularClassName = styles.countriesWithProject;
const selectedClassName = styles.countriesWithProjectSelected;
const hasProjectInCountry = true;

export default class extends React.PureComponent<IProps, {}> {
  render() {
    const {
      paths, selectedCountry, onSelect,
      onMouseEnter, onMouseLeave,
    } = this.props;
    const countries = paths.map(({id, path}: IDatum) => {
      const isSelected = (selectedCountry === id);
      return (
      <Country
        key={id}
        id={id} path={path} regularClassName={regularClassName}
        selectedClassName={selectedClassName} hasProjectInCountry={hasProjectInCountry}
        isSelected={isSelected} onSelect={onSelect}
        onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
        />
      );
    });
    return (
      <g>{countries}</g>
    );
  }
}
