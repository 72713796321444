import {
  GetString,
} from 'fluent-react';
import debounce from 'lodash/debounce';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  demiFontWeight,
} from '../cssVariables';
import { menuBaseColor } from '../cssVariables';
import { useLink } from '../routing';
import { RouteID } from '../routing/routes';
import {
  HeaderContainer,
} from '../sharedComponents/GlobalGrid';
import HamburgerMenu from '../sharedComponents/hamburgerMenu';
import Nav from '../sharedComponents/NewNav';

export const headerHeight = '62vh';

// Set `position: relative` so that this will appear in front of the header
// video:
const Root = styled(HeaderContainer)`
  min-height: ${headerHeight};
`;

const AtlasLogoImageContainer = styled.a`
  display: block;
  max-width: 250px;
`;
const AtlasLogoImage = styled.div`
  position: relative;
  top: 1.7rem;
  max-width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`;

const UpperContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.75rem;
`;
const LowerContentContainer = styled.div`
  letter-spacing: 0.02rem;
  color: white;
`;

const HeaderTitle = styled.p`
  font-weight: ${demiFontWeight};
  font-size: 50px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 1.6em;
  margin-bottom: 0.3em;
`;
const Description = styled.p`
  font-size: 24px;
  line-height: 1.25em;
  font-weight: ${demiFontWeight};
  text-align: center;
  max-width: 560px;
  margin: 0 auto 40px;
`;

interface Props {
  getFluentString: GetString;
}

const Header = (props: Props) => {
  const { getFluentString } = props;
  const logoAnchorProps = useLink({ id: RouteID.Home });

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const updateWindowWidth = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 500);
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  if (windowWidth < 675) {
    return (
      <Root>
        <UpperContentContainer>
          <AtlasLogoImageContainer {...logoAnchorProps}>
            <AtlasLogoImage>
              <img src={require('../img/GL_logo_white.png')} alt="The Atlas of Economic Complexity @ Harvard Growth Lab"/>
              </AtlasLogoImage>
          </AtlasLogoImageContainer>
            <HamburgerMenu iconColor={'#fff'} />
        </UpperContentContainer>
        <LowerContentContainer>
          <HeaderTitle>{getFluentString('header-text-main-title')}</HeaderTitle>
          <Description>{getFluentString('header-text-subtitle')} </Description>
        </LowerContentContainer>
      </Root>
    );
  }

  return (
    <Root>
      <UpperContentContainer>
        <AtlasLogoImageContainer {...logoAnchorProps}>
        <AtlasLogoImage>
              <img src={require('../img/GL_logo_white.png')} alt="The Atlas of Economic Complexity @ Harvard Growth Lab"/>
          </AtlasLogoImage>
        </AtlasLogoImageContainer>
        <Nav
          normalTextColor='white'
          highlightColor={menuBaseColor}
        />
      </UpperContentContainer>
      <LowerContentContainer>
        <HeaderTitle>{getFluentString('header-text-main-title')}</HeaderTitle>
        <Description>{getFluentString('header-text-subtitle')} </Description>
      </LowerContentContainer>
    </Root>
  );
};

export default Header;
