import React from 'react';
import styled from 'styled-components';

// TODO: find a better way to do make the twitter feed as tall as the Youtube
// video:
const Root = styled.div`
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  font-size: 0.4rem;
  min-height: 57vh;
`;

interface IState {
  height: number | undefined;
}
type IProps = {};
export default class extends React.PureComponent<IProps, IState> {
  private el: HTMLElement | null = null;
  private rememberEl = (el: HTMLElement | null) => this.el = el;
  constructor(props: {}) {
    super(props);
    this.state = {
      height: undefined,
    };
  }

  private measureDOMLayout() {
    if (this.el !== null) {
      const {height} = this.el.getBoundingClientRect();
      this.setState({height});
    }
  }

  componentDidMount() {
    this.measureDOMLayout();
  }
  componentDidUpdate() {
    if (this.el !== null && this.state.height !== undefined) {
      const script = document.createElement('script');
      script.src = '//platform.twitter.com/widgets.js';
      script.async = true;
      this.el.appendChild(script);
    }

  }

  render() {
    const {height} = this.state;
    let content: JSX.Element | null;
    if (height === undefined) {
      content = null;
    } else {
      content = (
        <a className='twitter-timeline' data-height={height} href='https://twitter.com/HarvardGrwthLab'>
          Tweets by Harvard Growth Lab
        </a>
      );
    }
    return (
      <Root ref={this.rememberEl}>
        {content}
      </Root>
    );
  }
}
