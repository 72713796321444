import React from 'react';
import styled from 'styled-components';
import { footerColor } from '../../sharedComponents/Footer';
import ListItem from './CountryListItem';
import {
  Overlay,
} from './DetailOverlay';
import {
  IProjectDatum,
} from './Utils';

const Root = styled(Overlay)`
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(238, 240, 243, 0.5);
  color: ${footerColor};
  padding: 2vh;
  padding-top: 3vh;
  font-size: 0.7rem;
`;

interface IProps {
  selectedCountry: number | undefined;
  data: Map<number, IProjectDatum>;
  onClick: (id: number) => void;
}

export default class extends React.PureComponent<IProps> {
  render() {
    const {onClick, data, selectedCountry} = this.props;

    const countries = [...data.entries()].map(([, {id, name}]) => (
      <ListItem
        id={id} name={name} selectedCountry={selectedCountry}
        onClick={onClick} key={id}
      />
    ));
    return (
      <Root>{countries}</Root>
    );
  }
}
