import styled from 'styled-components';

export const PageSection = styled.div`
  margin: 5vh 0 5vh;
  display: flex;
  min-width: 250px;
  grid-column: 2;
`;

export const breakpointSmall = 640;
