import { GetString } from 'fluent-react';
import React from 'react';
import styled from 'styled-components';
import { RouteID } from '../routing/routes';
import Card from './cards';
import { breakpointSmall } from './Utils';

const Container = styled.div`
  display: grid;
  grid-gap: 3rem;
  max-width: 1200px;
  margin: auto;
  grid-column: 2;

  @media (min-width: ${breakpointSmall}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

interface Props {
  getFluentString: GetString;
}

const IntroCards = (props: Props) => {
  const { getFluentString } = props;
  return (
    <Container>
      <Card
        title={getFluentString('intro-cards-explore-title')}
        body={getFluentString('intro-cards-explore-body')}
        ctaText={getFluentString('intro-cards-explore-cta')}
        ctaDestination={{id: RouteID.TreeMap}}
        placeholderImageUrl={require('./img/export-image-thumbnail.png')}
        videoUrl={require('./videos/export-intro-video.mp4')}
        showAward={false}
      />
      <Card
        title={getFluentString('intro-cards-country-profiles-title')}
        body={getFluentString('intro-cards-country-profiles-body')}
        ctaText={getFluentString('intro-cards-country-profiles-cta')}
        ctaDestination={{id: RouteID.CountryProfiles}}
        placeholderImageUrl={require('./img/country-profiles-landing.png')}
        videoUrl={require('./videos/cp-intro-video.mp4')}
        showAward={true}
      />
      <Card
        title={getFluentString('intro-cards-growth-projections-title')}
        body={getFluentString('intro-cards-growth-projections-body')}
        ctaText={getFluentString('intro-cards-growth-projections-cta')}
        ctaDestination={{ id: RouteID.GrowthProjectionsPressRelease }}
        placeholderImageUrl={require('./img/growth-image-thumbnail.png')}
        videoUrl={undefined}
        showAward={false}
      />
      <Card
        title={getFluentString('intro-cards-research-highlights-title')}
        body={getFluentString('intro-cards-research-highlights-body')}
        ctaText={getFluentString('intro-cards-research-highlights-cta')}
        ctaDestination={{id: RouteID.CountryRankings}}
        placeholderImageUrl={require('./img/research-image-thumbnail.png')}
        videoUrl={undefined}
        showAward={false}
      />
    </Container>
  );
};

export default IntroCards;
