import {
  IProjectDatumWithoutName,
} from './Utils';

export const countriesWithProjects: IProjectDatumWithoutName[] = [
  {
    id: 4,
    text: 'atlas-in-action-map-viz-explanation-texts-albania-description',
    link: 'https://albania.growthlab.cid.harvard.edu/',
    hasLink: true,
  },
  {
    id: 8,
    text: 'atlas-in-action-map-viz-explanation-texts-argentina-description',
    hasLink: false,
  },
  {
    id: 31,
    text: 'atlas-in-action-map-viz-explanation-texts-bolivia-description',
    hasLink: false,
  },
  {
    id: 32,
    text: 'atlas-in-action-map-viz-explanation-texts-brazil-description',
    hasLink: false,
  },
  {
    id: 49,
    text: 'atlas-in-action-map-viz-explanation-texts-colombia-description',
    link: 'https://colombia.growthlab.cid.harvard.edu/',
    hasLink: true,
  },
  {
    id: 65,
    text: 'atlas-in-action-map-viz-explanation-texts-dominican-description',
    link: 'https://growthlab.cid.harvard.edu/dominican-republic-project',
    hasLink: true,
  },
  {
    id: 73,
    text: 'atlas-in-action-map-viz-explanation-texts-ethiopia-description',
    hasLink: false,
  },
  {
    id: 83,
    text: 'atlas-in-action-map-viz-explanation-texts-ghana-description',
    link: 'https://growthlab.cid.harvard.edu/africa-growth-lab',
    hasLink: true,
  },
  {
    id: 115,
    text: 'atlas-in-action-map-viz-explanation-texts-kazakhstan-description',
    link: 'https://growthlab.cid.harvard.edu/kazakhstan-strategy-and-policy',
    hasLink: true,
  },
  {
    id: 116,
    text: 'atlas-in-action-map-viz-explanation-texts-kenya-description',
    link: 'https://growthlab.cid.harvard.edu/africa-growth-lab',
    hasLink: true,
  },
  {
    id: 125,
    text: 'atlas-in-action-map-viz-explanation-texts-liberia-description',
    link: 'https://growthlab.cid.harvard.edu/liberia-initiative',
    hasLink: true,
  },
  {
    id: 138,
    text: 'atlas-in-action-map-viz-explanation-texts-mexico-description',
    link: 'https://growthlab.cid.harvard.edu/mexico-atlas-economic-complexity',
    hasLink: true,
  },
  {
    id: 159,
    text: 'atlas-in-action-map-viz-explanation-texts-nigeria-description',
    link: 'https://growthlab.cid.harvard.edu/africa-growth-lab',
    hasLink: true,
  },
  {
    id: 169,
    text: 'atlas-in-action-map-viz-explanation-texts-panama-description',
    link: 'https://growthlab.cid.harvard.edu/panama',
    hasLink: true,
  },
  {
    id: 173,
    text: 'atlas-in-action-map-viz-explanation-texts-peru-description',
    link: 'https://growthlab.cid.harvard.edu/peru-atlas',
    hasLink: true,
  },
  {
    id: 187,
    text: 'atlas-in-action-map-viz-explanation-texts-rwanda-description',
    link: 'https://growthlab.cid.harvard.edu/africa-growth-lab',
    hasLink: true,
  },
  {
    id: 188,
    text: 'atlas-in-action-map-viz-explanation-texts-saudiarabia-description',
    hasLink: false,
  },
  {
    id: 246,
    text: 'atlas-in-action-map-viz-explanation-texts-southafrica-description',
    link: 'https://growthlab.cid.harvard.edu/south-africa-growth-initiative',
    hasLink: true,
  },
  {
    id: 128,
    text: 'atlas-in-action-map-viz-explanation-texts-srilanka-description',
    link: 'https://growthlab.cid.harvard.edu/sri-lanka',
    hasLink: true,
  },
  {
    id: 227,
    text: 'atlas-in-action-map-viz-explanation-texts-uganda-description',
    link: 'https://growthlab.cid.harvard.edu/africa-growth-lab',
    hasLink: true,
  },
  {
    id: 236,
    text: 'atlas-in-action-map-viz-explanation-texts-venezuela-description',
    hasLink: false,
  },
];

export const countriesIDsWithCurrentProjects = countriesWithProjects.map(({id}) => id);
