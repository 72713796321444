import {
  geoGraticule,
  GeoPermissibleObjects,
} from 'd3-geo';
import partition from 'lodash-es/partition';
import {
  IData,
} from '../../sharedData/newWorldGeoJSON';
import {
  ILoadable,
  LoadableStatus,
  toNumericMap,
} from '../../Utils';
import {
  IComputationalOutput,
  IDatum,
  PathGenerator,
} from './Utils';

const getSVGPaths = (
    generatePath: PathGenerator,
    countriesWithProjects: number[],
    geoJSONStatus: ILoadable<IData>,
  ): ILoadable<IComputationalOutput> => {

  let result: ILoadable<IComputationalOutput>;
  if (geoJSONStatus.status === LoadableStatus.Initial) {
    result = {status: LoadableStatus.Initial};
  } else if (geoJSONStatus.status === LoadableStatus.Loading) {
    result = {status: LoadableStatus.Loading};
  } else if (geoJSONStatus.status === LoadableStatus.NotPresent) {
    result = {status: LoadableStatus.NotPresent};
  } else {
    const {data: {features}} = geoJSONStatus;
    const pathList: IDatum[] = features.map( feature => {
      const path = generatePath(feature as any as GeoPermissibleObjects)!;
      return {
        id: feature.id,
        path,
        centerX: generatePath.centroid(feature as any as GeoPermissibleObjects)[0],
        bottomY: generatePath.bounds(feature as any as GeoPermissibleObjects)[1][1],
      };
    });
    const pathMap = toNumericMap(pathList, ({id}) => id);
    const [pathsForProjectCountries, pathsForNoProjectCountries] = partition(
      pathList,
      ({id}) => countriesWithProjects.includes(id),
    );
    const graticulePath = generatePath(geoGraticule()())!;
    result = {
      status: LoadableStatus.Present,
      data: {
        pathMap, graticulePath,
        pathsForNoProjectCountries, pathsForProjectCountries,
      },
    };
  }
  return result;
};

export default getSVGPaths;
